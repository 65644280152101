import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export const goAnimationMainProjects = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.to('.parallax-title__value', {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      trigger: '.parallax-title__value',
      endTrigger: 'parallax-title__value',
      start: () => `800 100%`,
      end: () => `3100 100%`,
    },
    duration: 3,
    opacity: 0,
  });
};
