import './PageCase.scss';
import './PageCase-media.scss';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import DocumentMeta from 'react-document-meta';
import Tabs from '../../components/Tabs/Tabs';
import { STUB_CASES } from './constants';
import { formatFilters, goAnimation, goAnimationMob } from './functions';
import { useGSAP } from '@gsap/react';
import { useEffect, useRef, useState } from 'react';
import { ServiceData } from '../../redux/services/ServiceRedux';
import { IModxCasePage } from '../../redux/services/InterfacesModx';
import { Props } from './interfaces';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { ConstructorCases } from '../../services/ConstructorCases';
import { Sticky } from '../../components/Sticky/Sticky';
import Header from '../../components/Header/Header/Header';
import Content from '../../components/Content/Content';

const PageCase = ({ lang, alertLoaded, widthWindow }: Props) => {
  const [data, setData] = useState<IModxCasePage>(STUB_CASES);
  const {
    object: {
      main_screen: { background, title, client, open_hours, industries, services },
      BlocksList,
      seo,
    },
  } = data;

  useEffect(() => {
    const id = /\/case\/([0-9]+)/.exec(window.location.href);
    if (id) ServiceData.getCasePage({ id: id[1], alertLoaded, success, en: lang === 'En' });
  }, [lang]);

  const success = (data: IModxCasePage) => {
    setData(data);
  };

  const container: any = useRef();
  useGSAP(
    () => {
      widthWindow > 720 ? goAnimation() : goAnimationMob();
    },
    { scope: container }
  );

  return (
    <div className="page-case" ref={container}>
      <DocumentMeta {...seo} />

      <Sticky>
        <Content block={<Header color="dark" />} />

        <div className="page-case__main">
          <h1 className="page-case__title">{title}</h1>
          <p className="body1 page-case__text">{client}</p>
          <p className="body1 page-case__hours">{open_hours}</p>

          <div className="page-case__info">
            <Tabs list={formatFilters(industries)} noHover itemTab={''} onChange={() => {}} />
            <Tabs list={formatFilters(services)} itemTab={''} onChange={() => {}} />
          </div>

          <div className="page-case__block-image">
            <img src={`${baseURLModx}/${background}`} className="page-case__image" alt="" />
          </div>
        </div>
      </Sticky>

      {BlocksList?.map((item, i) => (
        <ConstructorCases nameBlock={item.template} content={item} key={i} />
      ))}
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(PageCase));
