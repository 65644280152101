import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ITabCategory } from '../../components/Tabs/interfaces';

export const goAnimation = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.to('.page-case__image', {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      trigger: '.page-case__image',
      endTrigger: 'page-case__image',
      start: () => `0 80%`,
      end: () => `2000 80%`,
    },
    duration: 3,
    width: '100vw',
    height: '100dvh',
    bottom: '0',
  });

  setTimeout(() => {
    gsap.to('.page-case__title, .page-case__text, .page-case__hours', {
      scrollTrigger: {
        scrub: true,
        // markers: true,
        trigger: '.page-case__image',
        endTrigger: 'page-case__image',
        start: () => `300 80%`,
        end: () => `2000 80%`,
      },
      duration: 3,
      color: '#fff',
    });
  }, 200);
};

export const goAnimationMob = () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.to('.page-case__image', {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      trigger: '.page-case__image',
      endTrigger: 'page-case__image',
      start: () => `0 80%`,
      end: () => `2000 80%`,
    },
    duration: 3,
    height: '100dvh',
    bottom: '0',
  });

  gsap.to('.page-case__block-image', {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      trigger: '.page-case__image',
      endTrigger: 'page-case__image',
      start: () => `0 80%`,
      end: () => `2000 80%`,
    },
    duration: 3,
    width: '100vw',
    paddingLeft: '0',
    // transform: 'translateX(-16px)',
  });

  setTimeout(() => {
    gsap.to('.page-case__title, .page-case__text, .page-case__hours', {
      scrollTrigger: {
        scrub: true,
        // markers: true,
        trigger: '.page-case__image',
        endTrigger: 'page-case__image',
        start: () => `300 80%`,
        end: () => `2000 80%`,
      },
      duration: 3,
      color: '#fff',
    });
  }, 200);
};

export const formatFilters = (filters: string[]): ITabCategory[] => {
  const result: ITabCategory[] = [];

  filters.forEach((tag) => {
    result.push({
      text: tag,
      tab: tag,
    });
  });

  console.log(result);
  return result;
};
