import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Props } from './interfaces';
import './Header.scss';
import './Header-media.scss';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../../redux/services/Imports';
import { MediaContent, bodyAuto, bodyHidden } from '../../../services/GlobalFunctions';
import MenuTop from '../MenuTop/MenuTop';
import HeaderMobPopup from '../HeaderMobPopup/HeaderMobPopup';
import MenuHamburger from '../MenuHamburger/MenuHamburger';
import { MenuTopList, MenuTopListEn } from './constants';
import ButtonToggleLang from '../../ButtonToggleLang/ButtonToggleLang';

const Header = ({ color = 'dark', widthWindow, lang }: Props) => {
  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const [elCheckMenu, setElCheckMenu] = useState<React.ChangeEvent<HTMLInputElement> | null>(null);
  const [menu, setMenu] = useState(MenuTopList);

  useEffect(() => {
    setMenu(lang === 'En' ? MenuTopListEn : MenuTopList);
  }, [lang]);

  const closedMob = () => bodyAuto();
  const openPopup = () => {
    bodyHidden();
    setShowPopupMenu(true);
  };

  const closedPopup = (e?: React.ChangeEvent<HTMLInputElement> | null) => () => {
    bodyAuto();

    setShowPopupMenu(false);
    if (e) {
      e.target.checked = false;
    }
  };

  return (
    <header className={`header${color ? ` header_${color}` : ''}`}>
      <Link to="/" onClick={closedMob} className="header__logo-link">
        <img src={require('./img/logo.svg').default} className="header__logo" alt="logo" />
      </Link>

      {MediaContent(
        <div className="header__right-menu">
          <MenuTop menu={menu} />
          <ButtonToggleLang color={color} />
        </div>,
        null
      )}

      {MediaContent(
        null,
        <>
          <MenuHamburger
            onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.checked ? openPopup() : closedPopup(elCheckMenu)();
              setElCheckMenu(e);
            }}
          />
          <HeaderMobPopup closed={closedPopup(elCheckMenu)} show={showPopupMenu} />
        </>
      )}
    </header>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(Header));
