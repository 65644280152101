interface IFServerError {
  response: {
    status: number;
    data: any;
  };
  message: string;
}

export const GetServerTextError = (err: IFServerError) => {
  let message = '';
  switch (err.response.status) {
    case 400:
      const errors = err.response.data.object;
      Object.keys(errors).forEach((key: string) => {
        message += `${key}: ${errors[key]}<br>`;
      });
      break;
    case 401:
      message = err.response.data.message;
      break;
    default:
      message = err.message;
  }
  return message;
};

export const SetSeo = ({
  title,
  description,
  keywords,
}: {
  title: string;
  description: string;
  keywords: string;
}) => {
  const seo = { title, description };

  if (keywords) {
    return {
      ...seo,
      meta: {
        name: {
          keywords,
        },
      },
    };
  }

  return seo;
};
