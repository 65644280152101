import { IFProjectState } from '../initState/InterfacesState';

export const MapStateToProps = ({
  header,
  alert,
  article,
  widthWindow,
  offsetContent,
  lang,
}: IFProjectState) => {
  return {
    header,
    alert,
    article,
    widthWindow,
    offsetContent,
    lang,
  };
};
