import React from 'react';
import './CardLink.scss';
import './CardLink-media.scss';
import { Props } from './interfaces';
import { Link } from 'react-router-dom';
import { MediaContent } from '../../services/GlobalFunctions';

const CardLink = ({ image, link, title, children }: Props) => {
  return (
    <Link to={`/case/${link}`} className="card-link">
      <div className="card-link__image" style={{ backgroundImage: `url(${image})` }}>
        {children}
      </div>

      {MediaContent(null, title ? <p className="card-link__title">{title}</p> : null)}
    </Link>
  );
};

export default CardLink;
