import { useEffect } from 'react';
import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import './ButtonToggleLang.scss';
import { Props } from './interfaces';

const ButtonToggleLang = ({ lang, langLoaded, color = 'dark' }: Props) => {
  useEffect(() => {
    if (localStorage.getItem('mtgLang') === 'En') {
      langLoaded('En');
    } else {
      langLoaded('Ru');
    }
  }, []);

  const toggleTheme = () => {
    if (lang === 'Ru') {
      localStorage.setItem('mtgLang', 'En');
      langLoaded('En');
    } else {
      localStorage.setItem('mtgLang', 'Ru');
      langLoaded('Ru');
    }
  };

  return (
    <div className="lang">
      <p className={`lang__name${color ? ` lang__name_${color}` : ''}`} onClick={toggleTheme}>
        {lang === 'En' ? 'Ru' : 'En'}
      </p>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(ButtonToggleLang));
